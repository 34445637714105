export const useDebounceFn = (callback, wait) => {
    let timeoutId = null;
    const cancel = () => clearTimeout(timeoutId);
    const debouncedFn = (...args) => {
        cancel();
        timeoutId = setTimeout(async () => {
            await callback.apply(null, args);
        }, wait);
    };
    return debouncedFn;
};
