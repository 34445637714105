import './styles.css'
export function createFeedbackForm() {
    const getModal = () => document.querySelector('.modal__feedback');
    let closeController = null;
    const initClose = () => {
        if (closeController !== null) {
            closeController.abort();
        }
        closeController = new AbortController();
        getModal()?.addEventListener('click', function () {
            this.classList.remove('active');
        }, {signal: closeController.signal});
    };

    const openFeedback = function () {
        initClose();
        getModal()?.classList.add('active');
        return this
    };

    return {
        openFeedback
    };
}
