import {createErrorAlert} from "@/shared/ui/error-alert";
import {useDebounceFn} from "@/shared/useDebouncedFn";

const notificationServiceFactory = ({portal} = {}) => {
    const wrapper = portal ?? document.body;
    const removeAction = (node) => wrapper.removeChild(node);
    const removeDebouncedAction = useDebounceFn(removeAction, 1000);
    const add = (node) => wrapper.append(node);
    const alert = ({message, autoRemove = false, actionText, onClose}) => {
        const notification = createErrorAlert({message, onClose, actionText});
        add(notification);
        const remove = removeAction.bind(null, notification);
        const removeDebounced = removeDebouncedAction.bind(null, notification);
        if(autoRemove){
            removeDebounced()
        }
        return {
            remove,
            removeDebounced,
        }
    };

    return {
        alert,
    };
};

export const notificationService = notificationServiceFactory({
    portal: document.querySelector('.notification-portal')
});
