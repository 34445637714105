import {reachYMGoal} from "@/shared/analytics/ym";
import {cruiseListStore} from "@/entity/cruise/store/cruise-list.store";
import {orderStore} from "@/entity/order/store/order.store";
import {createPassengerCount} from './counter'
import './styles.css';
import {createPreorderDatepicker} from './datepicker';
import {creatPreorderSelectMenu} from "./select-menu";

export const createPreorderForm = () => {
    const form = document.querySelector('.preorder-form');
    form.addEventListener('change', () => {
        reachYMGoal('InitiateCheckout');
    });

    const datepicker = createPreorderDatepicker({
        el:document.getElementById('date_ext'),
        onSelect: orderStore.setDate
    });
    const passengerCounter = createPassengerCount();
    const directionSelect = creatPreorderSelectMenu({
        select: document.querySelector('.select-drop--cruise'),
        options: [],
        onSelect: (direction) => {
            orderStore.setDirection(direction.value);
        }
    });

    cruiseListStore.useEffect(() => {
        datepicker.updateNotDisabledDates(cruiseListStore.getDates())
    });
    orderStore.useDate(() => {
        const directionOptions = cruiseListStore.getDirectionOptionsByDate(orderStore.getDate());
        datepicker.setDate(orderStore.getDate());
        directionSelect.setOptions(directionOptions);
    });
    orderStore.useDirection(() => {
        directionSelect.selectOne(orderStore.getDirection());
    });

    return {
        setDisabled: passengerCounter.setDisabled,
        isDisabled: passengerCounter.isDisabled,
    }
};
