import {NotificationsGet} from "@/api/notifications.get";
import {notificationService} from "@/shared/notification.service";

export const useMarketNotification = async () => {
    try {
        const notificationList = await NotificationsGet();
        if (
            notificationList &&
            Array.isArray(notificationList) &&
            notificationList.length > 0
        ) {
            notificationService.alert({message: notificationList[0]['description']});
        }
    } catch (e) {
        console.error(['notification download error', e]);
    }
};
