import './styles.css';
import {useCallback} from "@/shared/useCallback";

export const createErrorAlert = ({message = '', actionText="Хорошо", onClose = null} = {}) => {
    const onCloseCb = useCallback(onClose);
    const nodeContainer = document.createElement('div');

    nodeContainer.innerHTML = `<div class="notification notification--alert">
        <div class="notification__text">${message}</div>
        <button type="button" class="notification__action-btn action-btn action-btn--alert action-btn--outlined">${actionText}</button>
    </div>`;
    const node = nodeContainer.firstElementChild;
    node
        .querySelector('.notification__action-btn')
        .addEventListener(
            'click',
            (event) => {
                node.remove();
                onCloseCb.call(event);
            }, {once: true}
        );
    return node;
};
