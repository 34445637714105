import './styles.css';
import {orderStore} from "@/entity/order/store/order.store";

export const createPassengerCount = () => {
    const counterEl = document.querySelector('.form-field.form-field-number .counter');
    const input = counterEl.querySelector('input');
    const getCurrentValue = () => +input.value;
    const maxValue = +input.getAttribute('data-max');
    const minValue = +input.getAttribute('data-min');

    const minusBtn = counterEl.querySelector('.minus');
    const plusBtn = counterEl.querySelector('.plus');

    const setDisabled = (disabled = true) => {
        if (disabled) {
            minusBtn.setAttribute('disabled', '');
            plusBtn.setAttribute('disabled', '');
        } else {
            minusBtn.removeAttribute('disabled');
            plusBtn.removeAttribute('disabled');
        }
    };
    const isDisabled = () =>
        minusBtn.hasAttribute('disabled') &&
        plusBtn.hasAttribute('disabled');

    minusBtn.addEventListener('click', () => orderStore.setPassengerCount(getCurrentValue() - 1));
    plusBtn.addEventListener('click', () => orderStore.setPassengerCount(getCurrentValue() + 1));
    if (getCurrentValue() === minValue) {
        minusBtn.disabled = true;
    } else if (getCurrentValue() === maxValue) {
        plusBtn.disabled = true;
    }

    orderStore.usePassengerCount(() => {
        const count = orderStore.getPassengerCount();
        input.value = count;

        if(!isDisabled()){
            if (count < maxValue && count > minValue) {
                minusBtn.disabled = false;
                plusBtn.disabled = false;
            } else if (count > minValue) {
                plusBtn.disabled = true;
            } else if (count < maxValue) {
                minusBtn.disabled = true;
            }
        }
    });
    return {
        isDisabled,
        setDisabled,
        getCurrentValue
    }
};
