import {createDropdownToggle} from "@/shared/ui/dropdown-toggle";
import {createSelectMenu} from "@/shared/ui/select-menu";

export const creatPreorderSelectMenu = ({select, options, onSelect, showSearch = false} = {}) => {
    const dropdownToggle = createDropdownToggle(
        select.closest('.form-field--dropdown',),
        {
            cn: 'form-field--dropdown-shown',
            onClick: (evt) => {
                selectMenu.toggleAction(evt);
            }
        },
    );
    const selectMenu = createSelectMenu({
        select,
        onSelect,
        options,
        showSearch,
        onShow: dropdownToggle.showOpened,
        onHide: dropdownToggle.showClosed
    });
    return {
        setOptions: selectMenu.setOptions,
        selectOne: selectMenu.selectOne,
        clearValue: () => {
            selectMenu.selectOne(null);
        }
    };
};
