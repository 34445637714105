import {createDropdownToggle} from "@/shared/ui/dropdown-toggle";
import {createDatepicker} from "@/shared/ui/datepicker";

export const createPreorderDatepicker = ({el, onSelect}) => {
    const dropdownToggle = createDropdownToggle(
        el.closest('.form-field--dropdown'),
        {
            cn: 'form-field--dropdown-shown',
            preventMousedown: true
        }
    );

    const datepickerInstance = createDatepicker({
            el,
            onSelect,
            offset: ({popper}) => (
                popper.height + popper.y === popper.height ? [0, 10] : [0, 30]
            )
        }
    );
    datepickerInstance.onHide(() => {
        dropdownToggle.showClosed()
    });
    datepickerInstance.onShow(() => {
        dropdownToggle.showOpened()
    });

    return datepickerInstance;
};
