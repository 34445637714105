export function createFaqAccordion(){
    let acc = document.querySelectorAll(".questions__item-info");
    for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function (e) {
            if (e.target.classList.contains('menu-btn__plus') || e.target.classList.contains('menu-btn') || e.target.classList.contains('questions__item-plus') || e.target.classList.contains('accordion')) {
                if (this.classList.contains('active')) {
                    this.classList.remove('active');
                    this.parentNode.classList.remove('active');
                } else {
                    acc.forEach(function (item) {
                        item.classList.remove('active');
                        item.parentNode.classList.remove('active');
                        let i = item.querySelector('.panel');
                        i.style.maxHeight = null;
                    });
                    this.classList.add('active');
                    this.parentNode.classList.add('active');
                }

                let panel = this.querySelector('.panel');

                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            }
        });
    }
}
