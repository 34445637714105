import Pristine from 'pristinejs'
import {createFeedbackForm} from "@/shared/ui/form/feedback";
import '@/shared/ui/styles/line-input.css'
import '@/shared/ui/styles/outline-textarea.css'
import './styles.css'
import {useActionModal} from "@/shared/ui/action-modal";

const showError = () => useActionModal({
    heading: 'Ошибка',
    text: "Не удалось отправить зявку, пожалуйста повторите позже или свяжитесь по телефону."
});
export function createFormCooperation() {
    const feedbackForm = createFeedbackForm();

    const cooperationForm = document.getElementById("feedback");
    const submitBtn = cooperationForm.querySelector('.btn-form');

    const pristine = new Pristine(
        cooperationForm,
        {
            classTo: 'cooperation__form-field',
            errorClass: 'line-input--error',
            errorTextParent: 'cooperation__form-field',
            errorTextTag: 'div',
            errorTextClass: 'error'
        }
    );
    cooperationForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        if (pristine.validate()) {
            submitBtn.disabled = true;
            const formData = new FormData(cooperationForm);
            const data = {};
            formData.forEach(function (value, key) {
                data[key] = value;
            });
            try {
                const result = await fetch('/mail.php', {body: JSON.stringify(data), method: 'POST'})

                if (result && result.ok) {
                    feedbackForm.openFeedback();
                    cooperationForm.reset();
                } else {
                    showError();
                }
            } catch (_) {
                showError();
            }

            submitBtn.disabled = false;
        }
    });
}
